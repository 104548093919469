import React from "react"
import * as Feather from "react-feather"
import { store } from '../utility/redux'

const new_applicants = store.getState().new_applicants

const navigationConfig = [
  {
    type: "groupHeader",
    groupTitle: "RAPORLAR",
    permissions: ["admin", "system-admin", "default", "company-admin", "super-admin"],
  },
  // {
  //   id: 'main',
  //   title: 'Özet',
  //   type: "item",
  //   icon: <Feather.Home size={20} />,
  //   permissions: ["company-admin", "super-admin"],
  //   navLink: '/dashboard',
  // },
  {
    id: 'app-reports',
    title: 'Raporlar',
    type: "item",
    icon: <Feather.BarChart2 size={20} />,
    permissions: ["admin", "system-admin", "default", "company-admin", "super-admin"],
    navLink: '/dashboard'
  },   
  // {
  //   id: 'user-reports',
  //   title: 'Kullanıcı Raporları',
  //   type: "item",
  //   icon: <Feather.BarChart size={20} />,
  //   permissions: ["super-admin"],
  //   navLink: '/user-reports'
  // },  
  // {
  //   id: 'campaign-reports',
  //   title: 'Kampanya Raporları',
  //   type: "item",
  //   icon: <Feather.PieChart size={20} />,
  //   permissions: ["super-admin"],
  //   navLink: '/campaign-reports'
  // }, 
  // {
  //   id: 'my-campaign-reports',
  //   title: 'Kampanya Raporları',
  //   type: "item",
  //   icon: <Feather.PieChart size={20} />,
  //   permissions: ["company-admin"],
  //   navLink: '/my-campaign-reports'
  // },     
  {
    type: "groupHeader",
    groupTitle: "UYGULAMA KULLANICILARI",
    permissions: ["super-admin"],
  },  
  {
    id: 'app-users',
    title: 'Tüm Kullanıcılar',
    icon: <Feather.Users size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/app-users'
  },  
  {
    id: 'user-requests',
    title: 'Kullanıcı Talepleri',
    type: "item",
    icon: <Feather.Database size={20} />,
    permissions: ["super-admin"],
    navLink: '/user-requests'
  },     
  {
    type: "groupHeader",
    groupTitle: "FIRSAT (KART) KULLANICILARI",
    permissions: ["super-admin"],
  },  
  {
    id: 'card-users',
    title: 'Tüm Kullanıcılar',
    icon: <Feather.Users size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/card-users'
  },  
  {
    id: 'new-requests',
    title: 'Onay Bekleyenler',
    type: "item",
    icon: <Feather.Database size={20} />,
    permissions: ["super-admin"],
    navLink: '/new-user-requests'
  },
  {
    type: "groupHeader",
    groupTitle: "ÜYE İŞYERİ",
    permissions: ["company-admin", "super-admin"],
  },  
  {
    id: 'companies',
    title: 'Üye İş Yerleri',
    icon: <Feather.MapPin size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/companies'
  },
  {
    id: 'requests',
    title: 'Yeni Başvurular',
    icon: <Feather.List size={20} />,
    type: "item",
    badge: new_applicants > 0 ? "warning" : "",
    badgeText: new_applicants,
    permissions: ["super-admin"],
    navLink: '/new-requests'
  },  
  {
    id: 'company-info',
    title: 'İşyerim',
    icon: <Feather.Bookmark size={20} />,
    type: "item",
    permissions: ["company-admin"],
    navLink: '/my-company'
  },  
  {
    type: "groupHeader",
    groupTitle: "KAMPANYA",
    permissions: ["company-admin", "super-admin"],
  },  
  {
    id: 'waiting-campaigns',
    title: 'Onay Bekleyenler',
    icon: <Feather.List size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/waiting-campaigns'
  },  
  {
    id: 'campaigns',
    title: 'Kampanyalar',
    icon: <Feather.AlignLeft size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/campaigns'
  },
  {
    id: 'my-campaigns',
    title: 'Kampanyalar',
    icon: <Feather.AlignLeft size={20} />,
    type: "item",
    permissions: ["company-admin"],
    navLink: '/my-campaigns'
  },  
  {
    id: 'campaign-categories',
    title: 'Kampanya Kategorileri',
    icon: <Feather.Star size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/campaign-categories'
  },   
  {
    id: 'add-new-campaign',
    title: 'Yeni Kampanya',
    icon: <Feather.PlusSquare size={20} />,
    type: "item",
    permissions: ["company-admin", "super-admin"],
    navLink: '/new-campaign'
  },    
  {
    type: "groupHeader",
    groupTitle: "TESİS & ETKİNLİKLER",
    permissions: ["company-admin", "super-admin"],
  },  
  {
    id: 'events',
    title: 'Etkinlikler',
    icon: <Feather.Calendar size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/events'
  },  
  {
    id: 'venues',
    title: 'Tesisler',
    icon: <Feather.Hexagon size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/venues'
  },  
  {
    id: 'event-categories',
    title: 'Etkinlik Kategorileri',
    icon: <Feather.Star size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/event-categories'
  },    
  {
    id: 'venue-categories',
    title: 'Tesis Kategorileri',
    icon: <Feather.Star size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/venue-categories'
  },   
  {
    type: "groupHeader",
    groupTitle: "BİLDİRİM",
    permissions: ["super-admin"],
  },       
  {
    id: 'send-notification',
    title: 'Bildirim Gönder',
    icon: <Feather.MessageCircle size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/send-notification'
  },
  {
    id: 'notifications',
    title: 'Bildirimler',
    icon: <Feather.Mail size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/notifications'
  },    
  {
    type: "groupHeader",
    groupTitle: "İÇERİK YÖNETİMİ",
    permissions: ["super-admin"],
  },   
  {
    id: 'news',
    title: 'Haberler',
    icon: <Feather.Paperclip size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/news'
  },
  {
    id: 'surveys',
    title: 'Anketler',
    icon: <Feather.Circle size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/surveys'
  },  
  // {
  //   id: 'survey-result',
  //   title: 'Anket Sonuçları',
  //   icon: <Feather.Circle size={20} />,
  //   type: "item",
  //   permissions: ["super-admin"],
  //   navLink: '/survey-result'
  // },      
  {
    id: 'main-slider',
    title: 'Ana Slider',
    icon: <Feather.Sliders size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/main-slider'
  },  
  {
    type: "groupHeader",
    groupTitle: "YÖNETİM",
    permissions: ["super-admin"],
  },       
  {
    id: 'admins',
    title: 'Yöneticiler',
    icon: <Feather.Users size={20} />,
    type: "item",
    permissions: ['admin', "editor", "system-admin", "super-admin"],
    navLink: '/admins'
  },
  {
    id: 'app-settings',
    title: 'Uygulama Ayarları',
    icon: <Feather.Settings size={20} />,
    type: "item",
    permissions: ['admin', "editor", "system-admin", "super-admin"],
    navLink: '/settings'
  },
  {
    id: 'faqs',
    title: 'Sıkça Sorulan Sorular',
    icon: <Feather.Command size={20} />,
    type: "item",
    permissions: ['admin', "editor", "system-admin", "super-admin"],
    navLink: '/faqs'
  },
  {
    id: 'user-text',
    title: 'KVKK Sözleşmesi',
    icon: <Feather.CheckSquare size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/kvkk'
  },
  {
    id: 'privacy-text',
    title: 'Gizlilik Politikası',
    icon: <Feather.CheckSquare size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/privacy'
  },
  {
    id: 'contact-info',
    title: 'İletişim Bilgileri',
    icon: <Feather.Info size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/contact'
  },
  {
    id: 'social-medias',
    title: 'Sosyal Medya Linkleri',
    icon: <Feather.ExternalLink size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/social-media'
  },
  {
    id: 'cities',
    title: 'Şehirler',
    icon: <Feather.GitCommit size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/cities'
  },
  {
    id: 'professions',
    title: 'Meslekler',
    icon: <Feather.Moon size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/professions'
  },
  {
    id: 'hobbies',
    title: 'İlgi Alanları',
    icon: <Feather.Aperture size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/hobbies'
  },
  {
    id: 'schools',
    title: 'Okullar',
    icon: <Feather.Book size={20} />,
    type: "item",
    permissions: ["super-admin"],
    navLink: '/schools'
  }
]

export default navigationConfig
